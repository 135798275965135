import { View, Text, Dimensions, TextInput, FlatList, Image, TouchableOpacity } from 'react-native'
import React, {useEffect, useState} from 'react'
import { Ionicons } from '@expo/vector-icons'; 
import { MotiView, MotiText  } from 'moti';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-web';
import { AntDesign } from '@expo/vector-icons'; 
import { MaterialIcons } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons'; 
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const {fullWidth, fullHeight} = Dimensions.get('window');
import {
  useFonts,  
  Lexend_100Thin,
  Lexend_300Light,
  Lexend_400Regular,
  Lexend_500Medium,
  Lexend_600SemiBold,
  Lexend_700Bold,
  Lexend_800ExtraBold,
} from '@expo-google-fonts/lexend';

const Search = () => {

    const navigation = useNavigation();

    const [product, setProduct] = useState([]);

    const DummyList = [{
        id: 1,
        author: 'Camwear',
        title: 'SPOP13CW110',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/1e4256c4-9052-487d-980a-f7363ffffc66SPOP13CW110-Camwear®-Spoon-13-Perforated-Black.jpg',
        category: 'Cutlery',
        description: 'SPOP13CW110-Camwear®-Spoon-13-Perforated-Black',
        special: 'true',
        featured: 'true'
    },
    {
        id: 2,
        author: 'Camcarrier',
        title: 'UPC400401-Camcarrier',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/955bfac3-4a5a-4d4e-bbe4-25dcb9e3f538UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-.jpg',
        category: 'Container',
        description: 'UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-',
        special: 'true',
        featured: 'false'
    },
    {
        id: 3,
        author: 'UPCH',
        title: 'UPCH Meal Delivery Cart',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
        category: 'Trolley',
        description: 'Meal delivery cart',
        special: 'true',
        featured: 'true'
    },
    {
        id: 4,
        author: 'Camwear',
        title: 'SPOP13CW110',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/1e4256c4-9052-487d-980a-f7363ffffc66SPOP13CW110-Camwear®-Spoon-13-Perforated-Black.jpg',
        category: 'Cutlery',
        description: 'SPOP13CW110-Camwear®-Spoon-13-Perforated-Black',
        special: 'true',
        featured: 'false',
    },
    {
        id: 5,
        author: 'Camcarrier',
        title: 'UPC400401-Camcarrier',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/955bfac3-4a5a-4d4e-bbe4-25dcb9e3f538UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-.jpg',
        category: 'Container',
        description: 'UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-',
        special: 'true',
        featured: 'false'
    },
    {
        id: 6,
        author: 'UPCH',
        title: 'UPCH Meal Delivery Cart',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
        category: 'Trolley',
        description: 'Meal delivery cart',
        special: 'true',
        featured: 'true'
    },
    {
        id: 7,
        author: 'Camcarrier',
        title: 'UPC400401-Camcarrier',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/955bfac3-4a5a-4d4e-bbe4-25dcb9e3f538UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-.jpg',
        category: 'Container',
        description: 'UPC400401-Camcarrier®-UPC-Capacity-4-Full-Size-',
    },
    {
        id: 8,
        author: 'UPCH',
        title: 'UPCH Meal Delivery Cart',
        image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
        category: 'Trolley',
        description: 'Meal delivery cart',
        special: 'true',
        featured: 'true'
    },
    ]


    let [fontsLoaded] = useFonts({
    
    Lexend_100Thin,
    Lexend_300Light,
    Lexend_400Regular,
    Lexend_500Medium,
    Lexend_600SemiBold,
    Lexend_700Bold,
    Lexend_800ExtraBold,
  });

  const deleteItem = async(item)=>{

            const removeItem = {
                id: item?.id,
            }

            await API.graphql({ query: mutations.deleteBook, variables: {input: removeItem}}).then((e)=> {
                    console.log('deleted')
            })
        } 
  const itemViewData = ({item})=>{
       

            if(!fontsLoaded){
                return(
                    <View style={{backgroundColor: 'white', flex: 1}}>

        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              {/* <Image source={{ uri: item.user.avatar }} style={styles.avatar} /> */}
              {/* <Image source={require('../../../NikomaLogo.png')} style={{width: 80, height: 80, resizeMode: 'contain'}} /> */}
            </View>

                    </View>
                )
            } else{
                return(

                    <View
                  style={{ marginRight: 50, marginBottom: 25, borderWidth: 1, borderColor: '#d1d1d1', borderRadius: 10, paddingLeft: 200, paddingRight: 200, marginLeft: 80, marginRight: 80}}
                >
                     <TouchableOpacity style={{flexDirection: 'row'}} >

                        <Image source={{uri: item?.image, marginRight: 30,}} style={{width: 120, height: 120, paddingTop: 30, paddingBottom: 30,
                        borderRadius: 10, resizeMode: 'contain', marginRight: 20, marginLeft: 20}} />

                        {/* Add to cart  and favourite  */}
                        <View style={{flexDirection: 'row', marginTop: -35, justifyContent: 'flex-end', paddingRight: 15, marginBottom: 5}}>
                            {/* heart */}

                            
                        </View>        

                        <View style={{ justifyContent: 'center', width: 250}}>
                            <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 15, marginBottom: 10}}>{item?.title}</Text>
                             <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 15, marginBottom: 10}}>{item?.author}</Text>
                             <Text style={{fontFamily: 'Lexend_300Light', fontSize: 15, marginBottom: 10}}>{item?.category}</Text>

                            


                        </View>

                        <View style={{justifyContent: 'center'}}>
                         

                            <TouchableOpacity style={{backgroundColor: 'black', marginLeft: 15, width: 120, height: 40, borderRadius: 20, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <AntDesign name="pluscircle" size={20} color="white" />
                                <Text style={{fontSize: 14, color: 'white', fontFamily: 'Lexend_500Medium'}}>Cart</Text>
                            </TouchableOpacity>   

                               
                        </View>
                        
                    </TouchableOpacity>
                </View>



                   
                )
            }

        
          
    }

    const itemViewData2 = ({item})=>{

              
       
                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{marginBottom: 25, borderBottomWidth: 1, borderColor: '#d1d1d1'}}
                >
                     <TouchableOpacity onPress={()=> navigation.navigate('ProductScreen', {title: item?.title, image: item?.image, author: item?.author, description: item?.description})} style={{flexDirection: 'row', paddingBottom: 10}} >

                        <Image source={{uri: item?.image, marginRight: 30,}} style={{width: 100, height: 100, paddingTop: 30, paddingBottom: 30,
                        borderRadius: 10, resizeMode: 'contain', marginRight: 20, marginLeft: 20}} />

                        {/* Add to cart  and favourite  */}
                        <View style={{flexDirection: 'row', marginTop: -35, justifyContent: 'flex-end', paddingRight: 15, marginBottom: 5}}>
                            {/* heart */}

                            
                        </View>        

                        <View style={{ justifyContent: 'center', width: 250}}>
                            <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 13, marginBottom: 10}}>{item?.title}</Text>
                             <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 13, marginBottom: 10}}>{item?.author}</Text>
                             <Text style={{fontFamily: 'Lexend_300Light', fontSize: 13, marginBottom: 10}}>{item?.category}</Text>

                            


                        </View>

                        <View style={{flexDirection: 'row'}}>

                        <View style={{justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>

                            


                         <TouchableOpacity onPress={()=> deleteItem(item)} style={{height: 35, width: 100, marginLeft: 40, borderRadius: 10, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                            <Feather  name="delete" size={15} color="white" />
                            <Text style={{fontSize: 12, color: 'white', fontFamily: 'Lexend_400Regular', marginLeft: 10}}>Delete</Text>
                        </TouchableOpacity>      
                        </View>



                        </View>
                        
                    </TouchableOpacity>
                </MotiView>



                   
                )
            

    }

    const itemViewData3 = ({item})=>{
       
        if(item?.featured === true){

                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{marginBottom: 25, borderBottomWidth: 1, borderColor: '#d1d1d1'}}
                >
                     <TouchableOpacity onPress={()=> navigation.navigate('ProductScreen', {title: item?.title, image: item?.image, author: item?.author, description: item?.description})} style={{flexDirection: 'row', paddingBottom: 10}} >

                        <Image source={{uri: item?.image, marginRight: 30,}} style={{width: 50, height: 50, paddingTop: 30, paddingBottom: 30,
                        borderRadius: 10, resizeMode: 'contain', marginRight: 20, marginLeft: 20}} />

                        {/* Add to cart  and favourite  */}
                        <View style={{flexDirection: 'row', marginTop: -35, justifyContent: 'flex-end', paddingRight: 15, marginBottom: 5}}>
                            {/* heart */}

                            
                        </View>        

                        <View style={{ justifyContent: 'center', width: 150}}>
                            <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 11, marginBottom: 10}}>{item?.title}</Text>
                             <Text style={{fontFamily: 'Lexend_300Light', fontSize: 10, marginBottom: 10}}>{item?.category}</Text>

                            


                        </View>

                        <View style={{flexDirection: 'row'}}>

                        <View style={{justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>

                            


                         <View style={{height: 35, width: 100, marginLeft: 20, borderRadius: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                            <MaterialIcons name="keyboard-arrow-right" size={20} color="#d1d1d1" />
                        </View>      
                        </View>



                        </View>
                        
                    </TouchableOpacity>
                </MotiView>
                   
                )
            }

    }


    const fetchprod = async() =>{

        const allProducts = await API.graphql({ query: queries.listBooks });
        const productItem = allProducts.data.listBooks.items
        setProduct(productItem)

    }

    useEffect(() => {
      fetchprod()
    }, [])
    



  return (
    <View style={{flex: 1, backgroundColor: 'white', flexDirection: 'row'}}>
        
        
        

        <View style={{width: '100%', marginTop: 25}}>

                {/* <View style={{height: 50, margin: 30, marginLeft: 30, width: fullWidth, backgroundColor: 'white', justifyContent: 'center'}}>
                    <View style={{width: 400, height: 50, borderWidth: 1.5, borderColor: '#919191', borderRadius: 20, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{width: 25, height: 25, borderRadius: 50, backgroundColor: '#919191', marginLeft: 15, alignItems: 'center', justifyContent: 'center'}}>
                            <Ionicons name="ios-search" size={15} color="white" />
                        </View>

                        <TextInput style={{height: '100%', width: 350, fontFamily: 'Lexend_400Regular', fontSize: 15, paddingLeft: 20, borderRadius: 20, borderWidth: 0}} placeholder={'Search For A Product'} />

                    </View>

                </View> */}
        

                <ScrollView showsHorizontalScrollIndicator={true}>

                
                <View style={{marginBottom: 20, marginTop: 5, marginLeft: 30}}>
                                <FlatList
                                    data={product}
                                    scrollEnabled={true}
                                    scrollIndicatorInsets={true}
                                    showsHorizontalScrollIndicator={false}
                                    keyExtractor={(item, index) => index.toString()}
                                    renderItem={itemViewData2}
                                    style={{overflow: 'visible', paddingLeft: 30, height: 200}}
                                />
                </View>

                </ScrollView>

        </View>

    </View>
  )
}

export default Search