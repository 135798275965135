import { View, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'

const ProductsDetails = () => {


    const navigation = useNavigation();

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <Text>ProductsDetails</Text>

    </View>
  )
}

export default ProductsDetails