import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import Orders from '../Screens/OrderScreen/Orders';
import QuoteDetails from '../Screens/OrderScreen/QuoteDetails';
import Description from '../Screens/ProductsScreen/Description';
const Stack = createStackNavigator();


const OrdersStack = () => {

    return (
      <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >
                <Stack.Screen 
                
                name="Orders">
                    {() => <Orders/>}
                </Stack.Screen>

                <Stack.Screen component={Description} name="Description"/> 
                <Stack.Screen component={QuoteDetails} name="QuoteDetails"/> 
            </Stack.Navigator>
       </View>
    );
};



export default OrdersStack;