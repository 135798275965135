import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import ProductsScreen from '../Screens/ProductsScreen/ProductsScreen';
import ProductsDetails from '../Screens/ProductsScreen/ProductsDetails';
const Stack = createStackNavigator();


const ProductStack = () => {

    return (
      <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >
                <Stack.Screen 
                
                name="Search">
                    {() => <ProductsScreen/>}
                </Stack.Screen>

                <Stack.Screen component={ProductsDetails} name="ProductDetails" options={{title: 'More about this...'}}/>
                {/* <Stack.Screen component={Category} name="Category"/>
                <Stack.Screen component={AllProducts} name="AllProducts"/> */}
            </Stack.Navigator>
       </View>
    );
};



export default ProductStack;