import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, Image, TouchableOpacity, TextInput } from 'react-native';

import { useState, useEffect } from 'react';
import { Entypo, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, FontAwesome   } from '@expo/vector-icons';

import {
  useFonts,  
  Lexend_100Thin,
  Lexend_300Light,
  Lexend_400Regular,
  Lexend_500Medium,
  Lexend_600SemiBold,
  Lexend_700Bold,
  Lexend_800ExtraBold,
} from '@expo-google-fonts/lexend';
import TopNa from './src/Router/TopNa';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './src/aws-exports';
Amplify.configure(awsconfig);


const { width, height } = Dimensions.get('window');

export default function App() {


  const [authModal, setAuthModal] = useState(false)
  const [authentii, setAuthentii] = useState('login')
  const [userr, setUserr] = useState();
   const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
const [phone_number, setPhone_number] = useState('');
const [code, setCode] = useState('');
const [authed, setAuthed] = useState(false)

let [fontsLoaded] = useFonts({
    
    Lexend_100Thin,
    Lexend_300Light,
    Lexend_400Regular,
    Lexend_500Medium,
    Lexend_600SemiBold,
    Lexend_700Bold,
    Lexend_800ExtraBold,
  });

  async function signIn() {
  
     Auth.signIn( username, password).then(user => {
          setUserr({user})
          setAuthed(true)
      setAuthModal(false)

      }).catch(err => 

        console.log('error :'+err)
  )}

  async function signOut() {
    try {
        await Auth.signOut().then((e)=> {setAuthed(false)})
    } catch (error) {
        console.log('error signing out: ', error);
    }
}


    const checkAuth = async()=>{

      try {
      
      
      const userAuthenticated =  await Auth.currentAuthenticatedUser();
      const userSub = userAuthenticated.attributes.sub
      

      if(userSub === null){
        console.log('not authenticated')
        setAuthed(false)
      }

      if(userSub !== null){
              console.log('user subs' ,userSub)
              setAuthed(true)
      }
        } catch (error) {
        console.log(error)
      }

    }

    useEffect(() => {
      checkAuth()
    }, [authed])    


     const logsign=()=>{

      if(authed !== true){
        return(
          <View style={{flexDirection: 'row', marginLeft: 15, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <TouchableOpacity onPress={()=> {setAuthModal(true)}} style={{flexDirection: 'row', justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                        
                        <View style={{height: '100%', justifyContent: 'center'}}>
                          <FontAwesome name="user-circle-o" size={20} color="gray" />
                        </View>
                        
                        
                        <View style={{height: '100%', justifyContent: 'center'}}>
                          <Text style={{color: 'gray', fontFamily: 'Lexend_400Regular', fontSize: 12, marginLeft: 15,}}>Login / Register</Text>
                        </View>
                      </TouchableOpacity>
                  </View>
        )
      } else{
        return(
          <View style={{flexDirection: 'row', marginLeft: 15, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <TouchableOpacity onPress={()=> signOut()} style={{flexDirection: 'row', justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                        
                        <View style={{height: '100%', justifyContent: 'center'}}>
                          <FontAwesome name="user-circle-o" size={20} color="gray" />
                        </View>
                        
                        <View style={{height: '100%', justifyContent: 'center'}}>
                          <Text style={{color: 'gray', fontFamily: 'Lexend_400Regular', fontSize: 12, marginLeft: 15,}}>Sign Out</Text>
                        </View>
                      </TouchableOpacity>
                  </View>
        )
    }
  }

     const authenti =()=>{
      if(authentii === 'login'){
        return(
          <View style={{marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: -30, justifyContent: 'space-evenly', height: '100%', flexDirection: 'row'}}>
            <View style={{width: 150, marginRight: 100}}>
              <Image source={require('./assets/NikomaLogo.png')} style={{width: 150, height: 200, resizeMode: 'contain'}} />
            </View>

            <View style={{width: 200, height: 200, backgroundColor: 'white'}}>
               <View style={{alignItems: 'center', marginTop: -20}}>
                            <Text style={{marginBottom: 5, fontSize: 16, fontFamily: 'Lexend_400Regular'}}>Login</Text>
                            <View style={{marginTop: 10}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>E-Mail</Text>
                            <TextInput 
                            placeholder='E-Mail'
                            onChangeText= {val => setUsername(val)}
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

                        
                            </View>

                            <View style={{marginTop: 10}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Password</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setPassword(val)}
                            placeholder='Password'
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

                        
                            </View>

                            <View style={{flexDirection: 'row', width: '100%', marginTop: 10,}}>
                            

                          
                            
                            </View>

                             <View style={{}}>
                            
                            <TouchableOpacity onPress={()=> signIn()} style={{width: 200, height: 45, backgroundColor: 'black', borderRadius: 10, alignItems: 'center', justifyContent: 'center', marginTop: 15, flexDirection: 'row'}}>
                                <Text style={{color: 'white', fontFamily: 'Lexend_600SemiBold', marginRight: 10}}>Login</Text>
                                {/* <FontAwesome name="send" size={15} color="white" /> */}
                                <EvilIcons name="arrow-right" size={25} color="white" />
                            </TouchableOpacity>

                        </View>
                        </View>
            </View>
          </View>
        )
      } 
    } 

    const authentib =()=>{
      if(authentii === 'login'){
        return(
          <View style={{marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: -50, justifyContent: 'space-evenly', height: '100%'}}>
            <View style={{width: '100%', alignItems: 'center'}}>
              <Image source={require('./assets/NikomaLogo.png')} style={{width: 150, height: 200, resizeMode: 'contain'}} />
            </View>

            <View style={{width: 200, height: 200, backgroundColor: 'white', paddingLeft: 50}}>
               <View style={{alignItems: 'center', marginTop: -20}}>
                            <Text style={{marginBottom: 5, fontSize: 16, fontFamily: 'Lexend_400Regular'}}>Login</Text>
                            <View style={{marginTop: 10}}>
                            
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>E-Mail</Text>
                            <TextInput 
                            placeholder='E-Mail'
                            onChangeText= {val => setUsername(val)}
                            style={{width: 280, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

                        
                            </View>

                            <View style={{marginTop: 10}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Password</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setPassword(val)}
                            placeholder='Password'
                            style={{width: 280, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

                        
                            </View>

                            

                             <View style={{}}>
                            
                            <TouchableOpacity onPress={()=> signIn()} style={{width: 200, height: 45, backgroundColor: 'black', borderRadius: 10, alignItems: 'center', justifyContent: 'center', marginTop: 15, flexDirection: 'row'}}>
                                <Text style={{color: 'white', fontFamily: 'Lexend_600SemiBold', marginRight: 10}}>Login</Text>
                                {/* <FontAwesome name="send" size={15} color="white" /> */}
                                <EvilIcons name="arrow-right" size={25} color="white" />
                            </TouchableOpacity>

                        </View>
                        </View>
            </View>
          </View>
        )
      } 
    }

    if(width === 800 || width > 800){

    

    if(authModal === true){
      return(
          <View style={{flex: 1, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center'}}>
         
            <View style={{width: '50%', height: '50%', borderWidth: 1, borderRadius: 10, borderColor: '#d1d1d1'}}>
                  <TouchableOpacity style={{padding: 30}} onPress={()=> setAuthModal(false)}>
            
                    <AntDesign name="close" size={24} color="black" />

                  </TouchableOpacity>
                  <View>
              {authenti()}
            </View>
            </View>


          </View>
      )
    }

    return(
      <View style={{flex: 1}}>
      <View style={{backgroundColor:'white', flexDirection: 'row', height: 50, marginLeft: 30, }}> 
                  
                  <View style={{justifyContent: 'flex-start'}}>
                    <Image style={{width: 100, height: 50, resizeMode: 'contain', paddingTop: 10 }}  source={require('./assets/NikomaLogo.png')} />
                  </View>

                    {logsign()}

                   
         

      </View>


        <TopNa/>
      </View>
    ) 
    }else if (width === 799 || width < 799) {


      if(authModal === true){
      return(
          <View style={{flex: 1, backgroundColor: 'white', alignItems: 'center'}}>
         
            <View style={{width: 300, height: 300, borderRadius: 10, borderColor: '#d1d1d1'}}>
                  <TouchableOpacity style={{padding: 30}} onPress={()=> setAuthModal(false)}>
            
                    <AntDesign name="close" size={24} color="black" />

                  </TouchableOpacity>
                  <View>
              {authentib()}
            </View>
            </View>

            
          




          </View>
      )
    }



       return(
      <View style={{flex: 1}}>
                  <View style={{height: 50, width: '100%', backgroundColor: 'white', flexDirection: 'row', borderBottomWidth: 1, borderColor: '#d1d1d1'}}>
                    <View style={{paddingLeft: 15}}>
                    <Image style={{width: 100, height: 50, resizeMode: 'contain', paddingTop: 10 }}  source={require('./assets/NikomaLogo.png')} />
                  </View>

                   {logsign()} 

                  </View>
                  
                    
        <View style={{flex: 1}}>
                  
                  {/* <Router/> */}
        </View>
        
      </View>
    ) 
      
    }




  return (
    <View style={styles.container}>
      <Text>{width}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
