import { View, Text, TouchableOpacity, FlatList, Image, ScrollView  } from 'react-native'
import React, {useEffect, useState} from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons'; 
import { MotiView, MotiText  } from 'moti';
import { Feather } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

const QuoteDetails = () => {


  const route = useRoute();
  const navigation = useNavigation();

   const [quotes, setQuotes] = useState([])


  const fetchprod = async() =>{

         const userData = await Auth.currentAuthenticatedUser();
        const userSub = userData.attributes.sub;
        // setUserIdd(userSub)

         let filter = {
          orderID: {
              eq: route?.params.id
          },

      };

        const allProducts = await API.graphql({ query: queries.listOrderProducts});
        const productItem = allProducts.data.listOrderProducts.items
        setQuotes(productItem)
        console.log('all ops',productItem)



    }



    useEffect(() => {
      fetchprod()
    }, [])

    const havemailed = ()=>{
      return(
        <View>
                <TouchableOpacity onPress={()=> updateEmailed()} style={{width: 60, height: 30, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: 13, fontFamily: 'Lexend_300Light', color: 'white' }}>Yes</Text>
                  </TouchableOpacity>
        </View>
      )
    }


    const updateEmailed = async()=>{

      const todoDetails = {
        id: route.params?.id,
        answered: 'Yes'
      };

      try{

      await API.graphql({ query: mutations.updateOrderB, variables: {input: todoDetails}});
        console.log('updated')
        navigation.navigate('Orders')
      }catch(e){
        console.log(e)
      }

    }

    const updateNoEmailed = async()=>{

      const todoDetails = {
        id: route.params?.id,
        answered: 'No'
      };

      try{

      await API.graphql({ query: mutations.updateOrderB, variables: {input: todoDetails}});
        console.log('updated')
        navigation.navigate('Orders')
      }catch(e){
        console.log(e)
      }

    }


    const DummyList = [
     
        {
          id: 1,
            orderID: 1,
            author: 'UPCH',
            title: 'UPCH Meal Delivery Cart',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
            category: 'Trolley',
            description: 'Meal delivery cart',
            qty: '3'
        },
        {
          id: 2,
            orderID: 1,
            author: 'Camwear',
            title: 'SPOP13CW110',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/1e4256c4-9052-487d-980a-f7363ffffc66SPOP13CW110-Camwear®-Spoon-13-Perforated-Black.jpg',
            category: 'Cutlery',
            description: 'SPOP13CW110-Camwear®-Spoon-13-Perforated-Black',
            special: 'true',
            featured: 'true',
            qty: '6'
        },
  
    ]

 const itemViewData = ({item})=>{
        if(item?.orderID === route?.params?.id ){

                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{ width: '100%', height: 60, backgroundColor: 'white',borderTopWidth: 0, borderBottomWidth: 1, borderColor: '#d1d1d1'}}
                >
                     <TouchableOpacity onPress={()=> navigation.navigate('Description', {id: item?.productID, image: item?.image})}
                     style={{width: '100%', height: '100%', flexDirection: 'row'}}
                     >

                        <View style={{height: '100%', width: 20, backgroundColor: '#aca7f2'}}/>

                        <Image source={{uri: item?.image}}  style={{width: 50, height: 50, marginTop: 5, resizeMode: 'contain'}}/>

                        <View style={{paddingLeft: 10, paddingTop: 10}}>

                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>{item?.title}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>Item ID : {item?.productID}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>Quantity : {item?.quantity}</Text>

                        </View>

                        
                        
                    </TouchableOpacity>
                </MotiView>

                )
            

        } 
          
    }

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
    
        <View style={{width: '100%', height: 49, borderBottomWidth: 1, borderColor: '#d1d1d1', paddingLeft: 30, flexDirection: 'row'}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{justifyContent: 'center', height: '100%'}}>
            <Ionicons name="arrow-back-circle-sharp" size={24} color="black" />

            </TouchableOpacity>

            <View style={{marginLeft: 10, justifyContent: 'center', height: '100%'}}>
                <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>{route.params?.id}</Text>
            </View>
        </View>

        <View style={{margin: 30}}>
            <View>
                <View style={{marginTop: 7.5, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>Quote ID</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.id}</Text>

                  </View>

                  <View style={{marginTop: 7.5, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>Date</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.date}</Text>

                  </View>



                  <View style={{marginTop: 15, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>Customer Name</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.fullName}</Text>

                  </View>

                   

                  <View style={{marginTop: 7.5, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>Company</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.company}</Text>

                  </View>

                  <View style={{marginTop: 7.5, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>E-Mail Address</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.email}</Text>

                  </View>

                  <View style={{marginTop: 7.5, flexDirection: 'row'}}>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', width: 200 }}>Phone Number</Text>
                       <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>:    {route.params?.phoneNumber}</Text>

                  </View>
                
                
                <View style={{flexDirection: 'row', marginTop: 15}}>
                 <Text style={{fontSize: 13, fontFamily: 'Lexend_300Light', paddingRight: 15}}>Have you emailed the quote ? : </Text>
                    <View style={{flexDirection:'row'}}>
                      
                  
                  <TouchableOpacity onPress={()=> updateEmailed()} style={{width: 60, height: 30, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: 13, fontFamily: 'Lexend_300Light', color: 'white' }}>Yes</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={()=> updateNoEmailed()} style={{width: 60, height: 30, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', justifyContent: 'center', marginLeft: 10}}>
                    <Text style={{fontSize: 13, fontFamily: 'Lexend_300Light', color: 'white' }}>No</Text>
                  </TouchableOpacity>
                  
                    </View>
                </View>
    
            </View>
        </View>

        <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular', marginLeft: 30, }}>Quotation Items</Text>

        <ScrollView>
          <View style={{marginTop: 30}}>
                          <FlatList
                              data={quotes}
                              scrollEnabled={true}
                              scrollIndicatorInsets={true}
                              showsHorizontalScrollIndicator={false}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={itemViewData}
                              style={{overflow: 'visible'}}
                          />
          </View>

          
        </ScrollView>

    </View>
  )
}

export default QuoteDetails