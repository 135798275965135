import { View, Text } from 'react-native'
import React from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { Entypo, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, FontAwesome   } from '@expo/vector-icons';
import ProductsScreen from '../Screens/ProductsScreen/ProductsScreen';
import ProductStack from './ProductStack';
import OrdersStack from './OrdersStack';
const Tab = createMaterialTopTabNavigator();
const TopNa = () => {


    const empty=()=>{
        return(
            <View>
                <Text>Empty</Text>
            </View>
        )
    }

  return (
      <NavigationContainer>
            <Tab.Navigator  screenOptions={{
            tabBarLabelStyle: { fontSize: 0 },
            tabBarActiveTintColor: '#090f2e',

            
            }}>



                <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <FontAwesome  name="list-alt" color={color} size={20}/>
                    
                }} name="Products" component={ProductStack} />


                <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <Octicons  name="bookmark" color={color} size={20}/>
                    
                }} name="Orders" component={OrdersStack} />

                </Tab.Navigator>
      </NavigationContainer>
   
  )
}

export default TopNa