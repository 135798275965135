import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import Search from '../Screens/Search/Search';
import Description from '../Screens/ProductsScreen/Description';
const Stack = createStackNavigator();


const SearchStack = () => {

    return (
      <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >
                <Stack.Screen 
                
                name="Search">
                    {() => <Search/>}
                </Stack.Screen>

                 <Stack.Screen component={Description} name="ProductScreen" options={{title: 'More about this...'}}/>
                {/* <Stack.Screen component={Category} name="Category"/>
                <Stack.Screen component={AllProducts} name="AllProducts"/> */}
            </Stack.Navigator>
       </View>
    );
};



export default SearchStack;