import { View, Text, Image, FlatList, TouchableOpacity, ScrollView } from 'react-native'
import React,{useEffect, useState} from 'react'
import { Ionicons } from '@expo/vector-icons'; 
import { MotiView, MotiText  } from 'moti';
import { Feather } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {
  useFonts,  
  Lexend_100Thin,
  Lexend_300Light,
  Lexend_400Regular,
  Lexend_500Medium,
  Lexend_600SemiBold,
  Lexend_700Bold,
  Lexend_800ExtraBold,
} from '@expo-google-fonts/lexend';
const Orders = () => {

  const navigation = useNavigation();

   let [fontsLoaded] = useFonts({
    
    Lexend_100Thin,
    Lexend_300Light,
    Lexend_400Regular,
    Lexend_500Medium,
    Lexend_600SemiBold,
    Lexend_700Bold,
    Lexend_800ExtraBold,
  });

  const [quotes, setQuotes] = useState([])
  const [filtOpt, setFiltOpt] = useState('No')
  

   const filterlist = [{
        id: 1,
        name: 'Not Responded',
        set: 'No'
    },
    {
        id: 2,
        name: 'Responded',
        set: 'Yes'
    },
        
    ]



   const DummyList = [
     {
        id: 1,
        date: '2 March 2022',
        answered: 'No',
        read: 'false',
        pdfUrl: 'false',
        items: [{
          id: 1,
            author: 'UPCH',
            title: 'UPCH Meal Delivery Cart',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
            category: 'Trolley',
            description: 'Meal delivery cart',
        },
        {
          id: 2,
            author: 'Camwear',
            title: 'SPOP13CW110',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/1e4256c4-9052-487d-980a-f7363ffffc66SPOP13CW110-Camwear®-Spoon-13-Perforated-Black.jpg',
            category: 'Cutlery',
            description: 'SPOP13CW110-Camwear®-Spoon-13-Perforated-Black',
            special: 'true',
            featured: 'true'
        },
      
      ]
    },
   {
        id: 2,
        date: '12 March 2022',
        answered: 'Yes',
        read: 'true',
        pdfUrl: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        items: [{
          id: 1,
            author: 'UPCH',
            title: 'UPCH Meal Delivery Cart',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/e5469bc5-b1c3-4245-920f-2f0f39dcc65cUPCH1600401-Ultra-Camcart®-with-Heated-Doors-.jpg',
            category: 'Trolley',
            description: 'Meal delivery cart',
        },
        {
          id: 2,
            author: 'Camwear',
            title: 'SPOP13CW110',
            image: 'https://nikoma70fc84dcb6a2457fade547c7f5cc1b82150932-dev.s3.us-east-1.amazonaws.com/public/images/1e4256c4-9052-487d-980a-f7363ffffc66SPOP13CW110-Camwear®-Spoon-13-Perforated-Black.jpg',
            category: 'Cutlery',
            description: 'SPOP13CW110-Camwear®-Spoon-13-Perforated-Black',
            special: 'true',
            featured: 'true'
        },
      
      ]
    },
   
 
    ]

      const fetchprod = async() =>{

         const userData = await Auth.currentAuthenticatedUser();
        const userSub = userData.attributes.sub;
        // setUserIdd(userSub)

         let filter = {
          userSub: {
              eq: userSub
          }
      };

        const allProducts = await API.graphql({ query: queries.listOrderBS});
        const productItem = allProducts.data.listOrderBS.items
        setQuotes(productItem)

    }



    useEffect(() => {
      fetchprod()
    }, [])




     const itemViewOpt = ({item})=>{
        if(filtOpt === item?.set){

            if(!fontsLoaded){
                return(
                    <View style={{backgroundColor: 'white', flex: 1}}>

        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              {/* <Image source={{ uri: item.user.avatar }} style={styles.avatar} /> */}
            </View>

                    </View>
                )
            } else{
                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{alignItems: 'center'}}
                >
                     <View>
                        <View style={{width: 150, height: 45, borderRadius: 20, borderWidth: 1, marginRight: 10, backgroundColor: 'black', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{color:'white', fontSize: 10, fontFamily: 'Lexend_400Regular'}}>{item?.name}</Text>
                        </View>
                    </View>
                </MotiView>



                   
                )
            }

        } else{
             if(!fontsLoaded){
                return(
                    <View style={{backgroundColor: 'white', flex: 1}}>

             <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            </View>

                    </View>
                )
            } else{
                return(
                    <View>
                         <TouchableOpacity onPress={()=> setFiltOpt(item?.set)} style={{width: 150, height: 45, borderRadius: 20, marginRight: 10, backgroundColor: 'white', borderColor: 'black', borderWidth: 1.5, alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{color:'black', fontSize: 10, fontFamily: 'Lexend_400Regular'}}>{item?.name}</Text>
                        </TouchableOpacity>
                    </View>
                )
            }    
        }
    }





    const itemViewData = ({item})=>{


        const str_a = item?.id.replace(/[^0-9\.]+/g, "")
              const result = Number(str_a.slice(0, 6));
        if(item?.answered === filtOpt ){

            
                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{ width: '100%', height: 80, backgroundColor: 'white', borderBottomWidth: 1, borderColor: '#d1d1d1'}}
                >
                     <TouchableOpacity onPress={()=> navigation.navigate('QuoteDetails', {id: item?.id, date: item?.createdAt, answered: item?.answered, email: item?.email, fullName: item?.fullName, company: item?.company, phoneNumber: item?.phoneNumber})} 
                     style={{width: '100%', height: '100%', flexDirection: 'row'}}
                     >

                        <View style={{height: '100%', width: 20, backgroundColor: '#aca7f2'}}/>

                        <View style={{paddingLeft: 10, paddingTop: 10}}>

                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>{item?.createdAt.split('T')[0]}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>{item?.email}</Text>
                              <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>QT{result}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>Responded : {item?.answered}</Text>

                        </View>

                        
                        
                    </TouchableOpacity>
                </MotiView>

                )
            

         } 
          
    }

    const itemViewDataRead = ({item})=>{

        if(item?.read === 'true' ){
const str_a = item?.id.replace(/[^0-9\.]+/g, "")
              const result = Number(str_a.slice(0, 6));
            
                return(

                    <MotiView
                  from={{
                    opacity: 0,
                    scale: 0.2,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{ width: '100%', height: 60, backgroundColor: 'white', borderBottomWidth: 1, borderColor: '#d1d1d1'}}
                >
                     <TouchableOpacity onPress={()=> navigation.navigate('QuoteDetails', {id: item?.id})} 
                     style={{width: '100%', height: '100%', flexDirection: 'row'}}
                     >

                        <View style={{height: '100%', width: 20, backgroundColor: '#a7f2df'}}/>

                        <View style={{paddingLeft: 10, paddingTop: 10}}>

                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>{item?.date.split('T')[0]}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>QT{result}</Text>
                          <Text style={{fontSize: 11, fontFamily: 'Lexend_300Light'}}>Responded : {item?.answered}</Text>

                        </View>

                        
                        
                    </TouchableOpacity>
                </MotiView>

                )
            

        } 
          
    }


  return (
    <View style={{backgroundColor: 'white', height: '100%', width: '100%'}}>
       
        <View style={{flexDirection: 'row', height: '100%'}}>

         

        <View style={{width: '100%', height: '100%'}}>
          <View style={{borderBottomWidth: 1, borderColor: '#d1d1d1'}}>
            <View style={{margin: 15, alignItems: 'center'}}>
                <Text style={{fontSize: 12, fontFamily: 'Lexend_400Regular'}}>Quotations</Text>
            </View>
          </View>

          <View style={{marginBottom: 20, marginTop: 10, alignItems: 'center'}}>
                        <FlatList
                            data={filterlist}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={itemViewOpt}
                            style={{overflow:'visible', paddingLeft: 30}}
                        />
        </View>
      <ScrollView>
         <View style={{}}>
                        <FlatList
                            data={quotes}
                            scrollEnabled={true}
                            scrollIndicatorInsets={true}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={itemViewData}
                            style={{overflow: 'visible'}}
                        />
        </View>

  

                          <FlatList
                              data={quotes}
                              scrollEnabled={true}
                              scrollIndicatorInsets={true}
                              showsHorizontalScrollIndicator={false}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={itemViewDataRead}
                              style={{overflow: 'visible'}}
                          />
        </ScrollView>

        </View>


        </View>

        



    </View>
  )
}

export default Orders