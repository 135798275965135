/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      title
      description
      image
      author
      category
      featured
      price
      orders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user
      date
      total
      books {
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        date
        total
        createdAt
        updatedAt
        customer
      }
      nextToken
    }
  }
`;
export const getNewOrder = /* GraphQL */ `
  query GetNewOrder($id: ID!) {
    getNewOrder(id: $id) {
      id
      name
      surname
      email
      orderNo
      createdAt
      updatedAt
    }
  }
`;
export const listNewOrders = /* GraphQL */ `
  query ListNewOrders(
    $filter: ModelNewOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        email
        orderNo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewCart = /* GraphQL */ `
  query GetNewCart($id: ID!) {
    getNewCart(id: $id) {
      id
      orderNo
      title
      description
      createdAt
      updatedAt
    }
  }
`;
export const listNewCarts = /* GraphQL */ `
  query ListNewCarts(
    $filter: ModelNewCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNo
        title
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      name
      surname
      email
      createdAt
      updatedAt
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModeluserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCartProduct = /* GraphQL */ `
  query GetCartProduct($id: ID!) {
    getCartProduct(id: $id) {
      id
      userSub
      quantity
      option
      title
      image
      description
      author
      category
      productID
      createdAt
      updatedAt
    }
  }
`;
export const listCartProducts = /* GraphQL */ `
  query ListCartProducts(
    $filter: ModelCartProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        quantity
        option
        title
        image
        description
        author
        category
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      quantity
      image
      productID
      Book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      orderID
      OrderB {
        id
        userSub
        answered
        fullName
        company
        phoneNumber
        qty
        email
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        image
        productID
        orderID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderB = /* GraphQL */ `
  query GetOrderB($id: ID!) {
    getOrderB(id: $id) {
      id
      userSub
      answered
      fullName
      company
      phoneNumber
      qty
      email
      read
      createdAt
      updatedAt
    }
  }
`;
export const listOrderBS = /* GraphQL */ `
  query ListOrderBS(
    $filter: ModelOrderBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderBS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        answered
        fullName
        company
        phoneNumber
        qty
        email
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
