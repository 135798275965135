import { View, Text, TouchableOpacity, TextInput, Platform, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons'; 
import { FontAwesome } from '@expo/vector-icons'; 
import { ScrollView } from 'react-native';
import SearchStack from '../../Router/SearchStack';
import uuid from 'react-native-uuid'; 
import {
  useFonts,  
  Lexend_100Thin,
  Lexend_300Light,
  Lexend_400Regular,
  Lexend_500Medium,
  Lexend_600SemiBold,
  Lexend_700Bold,
  Lexend_800ExtraBold,
} from '@expo-google-fonts/lexend';
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import {Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import * as ImagePicker from 'expo-image-picker';
import config from '../../aws-exports'

const uii = uuid.v4();

//   const {
//     aws_user_files_s3_bucket_region: region,
//     aws_user_files_s3_bucket: bucket
// } = config
const ProductsScreen = () => {

    let [fontsLoaded] = useFonts({
    
    Lexend_100Thin,
    Lexend_300Light,
    Lexend_400Regular,
    Lexend_500Medium,
    Lexend_600SemiBold,
    Lexend_700Bold,
    Lexend_800ExtraBold,
  });

  const navigation = useNavigation();

  const [fullName, setFullName] = useState('')
  const [imageUri, setImageUri] = useState(null);
  const [urll, setUrll] = useState('')
  const [image, setImage] = useState(null);

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [author, setAuthor] = useState('')
  const [category, setCategory] = useState('')
  const [featured, setFeatured] = useState(false)


  const [percentage, setPercentage] = useState(0);

  const theDate = new Date().toISOString();

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    handleUpload(result)

      // console.log(result);
      setImageUri(result?.uri)
      // console.log('typ',result.type)
      
    if (!result.cancelled) {
      setImageUri(result?.uri);
    }
  };

  const handleUpload = async(pickerResult)=>{
    // const file = imageUri[0];
    // const extension = file.split(".")[0];
    // const name = file.split(".")[0];
    // const key = `images/${'5'}${name}.${extension}`;
    // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
    // console.log('file ', file)
    // console.log('start')

    console.log('ui ', uii)

    const urll = 'images/'+uii+'.jpg'
   
    const img = await fetchImageFromUri(pickerResult.uri);
    // const uploadUrl = await uploadImage("images/da.jpg", img);
        const uploadUrl = await uploadImage(urll, img);

     downloadImage(uploadUrl);

    // try {
    //         await Storage.put(key, file, {
    //             level: 'public',
    //             contentType: file.type
    //         });

    //         console.log('uploaded')
    //         const image = await Storage.get(key, { level: 'public' })
    //         console.log('retrieved')
    //         setImageUri(image);
    //         setUrll(url)
    //         console.log(url)
    //     } catch (err) {
    //         console.log(err);
    //     }
  }

 const uploadImage = (filename, img) => {
    Auth.currentCredentials();
    return Storage.put(filename, img, {
      level: "public",
      contentType: "image/jpeg",
      progressCallback(progress) {
        setLoading(progress);
      },
    })
      .then((response) => {
        return response.key;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  };

  const setLoading = (progress) => {
    const calculated = parseInt((progress.loaded / progress.total) * 100);
    updatePercentage(calculated); // due to s3 put function scoped
    console.log(calculated)
  };

    const updatePercentage = (number) => {
    setPercentage(number);
    console.log(number)
  };

   const downloadImage = (uri) => {
    Storage.get(uri)
      .then((result) => {setImage(result), console.log('img url',result)})
      .catch((err) => console.log(err));

      
  };

  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

   
  

  const addData = async()=>{
                const addOne = {
                    title: title,
                    description: description,
                    image: image,
                    author: author,
                    category: category,
                    featured: false
                }

                await API.graphql({ query: mutations.createBook, variables: {input: addOne}}).then((e)=> {
                    console.log('success'),
                    setTitle(''),
                    setDescription(''),
                    setAuthor(''),
                    setCategory(''),
                    setImage('')
                })

            }

  return (
    <View style={{flex: 1, backgroundColor: 'white', flexDirection: 'row'}}>
        <View style={{width: '30%', height: '100%', borderRightWidth: 1, borderColor: '#d1d1d1'}}>
            <View style={{height: 50, width: '100%', borderBottomWidth: 1, borderColor: '#d1d1d1', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row'}}>
                <Text style={{fontFamily: 'Lexend_400Regular', fontSize: 12, paddingLeft: 25}}>Add New Product {theDate.split('T')[0]}</Text>
                <Ionicons name="md-add-circle" size={24} color="green" style={{paddingRight: 25}} />
            </View>

            <ScrollView>

                  <View style={{marginLeft: 15, marginTop:15}}>
            <View style={{width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                
            <TouchableOpacity onPress={()=> pickImage()} style={{width: 100, height: 100, borderColor: '#d1d1d1', borderRadius: 10, borderWidth: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <FontAwesome name="upload" size={24} color="#d1d1d1" />
            </TouchableOpacity>
                            {imageUri && <Image source={{ uri: imageUri }} style={{ width: 100, height: 100, resizeMode: 'contain', marginLeft: 15 }} />}
            </View>

            <View style={{marginTop: 15}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Product Name</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setTitle(val)}
                            placeholder='Product Name'
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

            </View>

            <View style={{marginTop: 15}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Category</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setCategory(val)}
                            placeholder='Category'
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

            </View>

             <View style={{marginTop: 15}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Brand</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setAuthor(val)}
                            placeholder='Brand'
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

            </View>

             <View style={{marginTop: 15}}>
                            
                            {/* name */}
                            <Text style={{marginBottom: 10, fontFamily: 'Lexend_300Light', fontSize: 12}}>Description</Text>
                            {/* Text Input */}
                            <TextInput 
                            onChangeText= {val => setDescription(val)}
                            placeholder='Description'
                            style={{width: 350, height: 45, padding: 10, borderRadius: 10, borderWidth: 1.5, borderColor: '#d1d1d1', fontFamily: 'Lexend_300Light'}} />

            </View>

               <View style={{}}>
                            
                        <TouchableOpacity onPress={()=> {addData()}} style={{width: 350, height: 45, backgroundColor: 'black', borderRadius: 10, alignItems: 'center', justifyContent: 'center', marginTop: 25, flexDirection: 'row'}}>
                            <Text style={{color: 'white', fontFamily: 'Lexend_600SemiBold', marginRight: 10}}>Submit</Text>
                            <FontAwesome name="send" size={15} color="white" />
                        </TouchableOpacity>

                        </View>
                  </View>
          </ScrollView>

        </View>

        <View style={{width: '70%', height: '100%'}}>
          <SearchStack/>
        </View>

        

    </View>
  )
}

export default ProductsScreen