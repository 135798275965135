/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook(
    $input: CreateBookInput!
    $condition: ModelBookConditionInput
  ) {
    createBook(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      category
      featured
      price
      orders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook(
    $input: UpdateBookInput!
    $condition: ModelBookConditionInput
  ) {
    updateBook(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      category
      featured
      price
      orders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook(
    $input: DeleteBookInput!
    $condition: ModelBookConditionInput
  ) {
    deleteBook(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      category
      featured
      price
      orders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBookOrder = /* GraphQL */ `
  mutation CreateBookOrder(
    $input: CreateBookOrderInput!
    $condition: ModelBookOrderConditionInput
  ) {
    createBookOrder(input: $input, condition: $condition) {
      id
      book_id
      order_id
      book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const updateBookOrder = /* GraphQL */ `
  mutation UpdateBookOrder(
    $input: UpdateBookOrderInput!
    $condition: ModelBookOrderConditionInput
  ) {
    updateBookOrder(input: $input, condition: $condition) {
      id
      book_id
      order_id
      book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const deleteBookOrder = /* GraphQL */ `
  mutation DeleteBookOrder(
    $input: DeleteBookOrderInput!
    $condition: ModelBookOrderConditionInput
  ) {
    deleteBookOrder(input: $input, condition: $condition) {
      id
      book_id
      order_id
      book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      books {
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      books {
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      books {
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const createNewOrder = /* GraphQL */ `
  mutation CreateNewOrder(
    $input: CreateNewOrderInput!
    $condition: ModelNewOrderConditionInput
  ) {
    createNewOrder(input: $input, condition: $condition) {
      id
      name
      surname
      email
      orderNo
      createdAt
      updatedAt
    }
  }
`;
export const updateNewOrder = /* GraphQL */ `
  mutation UpdateNewOrder(
    $input: UpdateNewOrderInput!
    $condition: ModelNewOrderConditionInput
  ) {
    updateNewOrder(input: $input, condition: $condition) {
      id
      name
      surname
      email
      orderNo
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewOrder = /* GraphQL */ `
  mutation DeleteNewOrder(
    $input: DeleteNewOrderInput!
    $condition: ModelNewOrderConditionInput
  ) {
    deleteNewOrder(input: $input, condition: $condition) {
      id
      name
      surname
      email
      orderNo
      createdAt
      updatedAt
    }
  }
`;
export const createNewCart = /* GraphQL */ `
  mutation CreateNewCart(
    $input: CreateNewCartInput!
    $condition: ModelNewCartConditionInput
  ) {
    createNewCart(input: $input, condition: $condition) {
      id
      orderNo
      title
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateNewCart = /* GraphQL */ `
  mutation UpdateNewCart(
    $input: UpdateNewCartInput!
    $condition: ModelNewCartConditionInput
  ) {
    updateNewCart(input: $input, condition: $condition) {
      id
      orderNo
      title
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewCart = /* GraphQL */ `
  mutation DeleteNewCart(
    $input: DeleteNewCartInput!
    $condition: ModelNewCartConditionInput
  ) {
    deleteNewCart(input: $input, condition: $condition) {
      id
      orderNo
      title
      description
      createdAt
      updatedAt
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModeluserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      name
      surname
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModeluserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      name
      surname
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModeluserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      name
      surname
      email
      createdAt
      updatedAt
    }
  }
`;
export const createCartProduct = /* GraphQL */ `
  mutation CreateCartProduct(
    $input: CreateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    createCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      image
      description
      author
      category
      productID
      createdAt
      updatedAt
    }
  }
`;
export const updateCartProduct = /* GraphQL */ `
  mutation UpdateCartProduct(
    $input: UpdateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    updateCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      image
      description
      author
      category
      productID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCartProduct = /* GraphQL */ `
  mutation DeleteCartProduct(
    $input: DeleteCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    deleteCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      image
      description
      author
      category
      productID
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      image
      productID
      Book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      orderID
      OrderB {
        id
        userSub
        answered
        fullName
        company
        phoneNumber
        qty
        email
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      image
      productID
      Book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      orderID
      OrderB {
        id
        userSub
        answered
        fullName
        company
        phoneNumber
        qty
        email
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      image
      productID
      Book {
        id
        title
        description
        image
        author
        category
        featured
        price
        createdAt
        updatedAt
      }
      orderID
      OrderB {
        id
        userSub
        answered
        fullName
        company
        phoneNumber
        qty
        email
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrderB = /* GraphQL */ `
  mutation CreateOrderB(
    $input: CreateOrderBInput!
    $condition: ModelOrderBConditionInput
  ) {
    createOrderB(input: $input, condition: $condition) {
      id
      userSub
      answered
      fullName
      company
      phoneNumber
      qty
      email
      read
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderB = /* GraphQL */ `
  mutation UpdateOrderB(
    $input: UpdateOrderBInput!
    $condition: ModelOrderBConditionInput
  ) {
    updateOrderB(input: $input, condition: $condition) {
      id
      userSub
      answered
      fullName
      company
      phoneNumber
      qty
      email
      read
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderB = /* GraphQL */ `
  mutation DeleteOrderB(
    $input: DeleteOrderBInput!
    $condition: ModelOrderBConditionInput
  ) {
    deleteOrderB(input: $input, condition: $condition) {
      id
      userSub
      answered
      fullName
      company
      phoneNumber
      qty
      email
      read
      createdAt
      updatedAt
    }
  }
`;
