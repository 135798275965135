import { View, Text, TouchableOpacity, Dimensions, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
import { Ionicons } from '@expo/vector-icons'; 
import { MotiView, MotiText  } from 'moti';
import { Feather } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  useFonts,  
  Lexend_100Thin,
  Lexend_300Light,
  Lexend_400Regular,
  Lexend_500Medium,
  Lexend_600SemiBold,
  Lexend_700Bold,
  Lexend_800ExtraBold,
} from '@expo-google-fonts/lexend';
// import { TouchableOpacity } from 'react-native-web';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
const Description = () => {

    let [fontsLoaded] = useFonts({
    
    Lexend_100Thin,
    Lexend_300Light,
    Lexend_400Regular,
    Lexend_500Medium,
    Lexend_600SemiBold,
    Lexend_700Bold,
    Lexend_800ExtraBold,
  });

  const {fullWidth, fullHeight} = Dimensions.get('window')

  const [qty, setQty] = useState(1)
  const [userIdd, setUserIdd] = useState();
  const [authed, setAuthed] = useState(false);
  const [produc, setProduc] = useState([]);

    const navigation = useNavigation();
    const route = useRoute();

    

    const checkAuth = async()=>{
      try {
          
        const userData = await Auth.currentAuthenticatedUser();
        const userSub =  userData.attributes.sub
        setAuthed(true)
        setUserIdd(userSub)


        const allProducts = await API.graphql(graphqlOperation(queries.getBook, {id: route?.params.id}));
        console.log('spec ite', allProducts)
        
        setProduc(allProducts.data.getBook)

        } catch (error) { 
          console.log(error)
          setAuthed(false)
        }
    }





    useEffect(() => {
          
      checkAuth()
    }, [])
    

    
   

  return (
    <View style={{backgroundColor: 'white', flex: 1}}>
        <View style={{width: '100%', height: 50, borderBottomWidth: 1, borderColor: '#d1d1d1', paddingLeft: 30, flexDirection: 'row'}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{justifyContent: 'center', height: '100%'}}>
            <Ionicons name="arrow-back-circle-sharp" size={24} color="black" />

            </TouchableOpacity>

            <View style={{marginLeft: 10, justifyContent: 'center', height: '100%'}}>
                <Text style={{fontSize: 13, fontFamily: 'Lexend_400Regular' }}>{route.params?.id}</Text>
            </View>
        </View>

        <View style={{height: '100%', flexDirection: 'row'}}>
                {/* image */}

                <MotiView
                  from={{
                    opacity: 0.7,
                    scale: 0.7,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: 'spring',
                  }}

                  style={{alignItems: 'center'}}
                >

                        <View style={{height: '100%', width: 400, alignItems:'center', justifyContent:'center'}}>
                            <Image source={{uri: route.params?.image}} style={{height: 300, width: 300, resizeMode: 'contain'}} />
                        </View>
                 
                </MotiView> 

                {/* information */}
                <View style={{height: '100%', width: '100%'}}>
                    <View style={{marginTop: 30, marginLeft: 30}}>
                        <Text style={{fontSize: 16, fontFamily: 'Lexend_300Light'}}>{produc?.author}</Text>
                        <Text style={{fontSize: 20, fontFamily: 'Lexend_500Medium'}}>{produc?.title}</Text>
                    </View>

                    <View style={{marginTop: 30, marginLeft: 30}}>
                        <Text style={{fontSize: 10, fontFamily: 'Lexend_600SemiBold', marginBottom: 10}}>DESCRIPTION</Text>
                        <Text style={{fontSize: 16, fontFamily: 'Lexend_300Light'}}>{produc?.description}</Text>
                    </View>

                    {/* Quantity */}

                    

                </View>
        </View>

    </View>
  )
}

export default Description